import { combineReducers } from 'redux'

import settingsReducer from './settings/reducer'
import userReducer from './user/reducer'
// import pagesReducer from './pages/reducer'

export default combineReducers({
    settings: settingsReducer,
    user: userReducer,
    // pages: pagesReducer,
})