
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import React from 'react'
import store from './services/store'

const Root = ({ children, initialState = {} }) => (
    <React.Fragment>
        {localStorage.getItem('customCSS') !== "NULL" && (
            <Helmet>
                <style type="text/css">{localStorage.getItem('customCSS')}</style>
            </Helmet>
        )}
        <Provider store={store(initialState)}>{children}</Provider>
    </React.Fragment>
)


export default Root;
