import { WEBSITE_URL } from "./website"

export const FEATURED_MOVIE_URL = WEBSITE_URL + "/data/featured/"
export const TV_SHOWS_URL = WEBSITE_URL + "/data/tvshows/"
// export const LATEST_MOVIE_URL = WEBSITE_URL + "/data/latest/"
export const WATCH_MOVIE_URL = WEBSITE_URL + "/data/watch/"
export const SEASONS_URL = WEBSITE_URL + "/data/seasons/"
export const BROWSE_MOVIE_URL = WEBSITE_URL + "/data/browse/"
export const TRANSLATION_URL = WEBSITE_URL + "/data/translation/"
export const LOGIN_URL = WEBSITE_URL + "/user/login/"
export const REGISTER_URL = WEBSITE_URL + "/user/register/"
export const WATCHLIST_URL = WEBSITE_URL + "/user/watchlist/"
export const WATCHLIST_CHECK_URL = WEBSITE_URL + "/user/watchlistCheck/"
export const UPDATE_INFO_URL = WEBSITE_URL + "/user/updateInfo/"
export const ADD_STREAM_URL = WEBSITE_URL + "/user/addStream/"
export const MY_LINKS_URL = WEBSITE_URL + "/user/myLinks/"
export const REQ_STREAM_URL = WEBSITE_URL + "/user/reqStream/"
export const SAVE_HISTORY_URL = WEBSITE_URL + "/user/saveHistory/"
export const SAVE_SETTING_URL = WEBSITE_URL + "/user/saveSetting/"
export const UPDATES_URL = WEBSITE_URL + "/data/updates/"
export const RELATED_URL = WEBSITE_URL + "/data/related/"
export const RELATED_MOVIES_URL = WEBSITE_URL + "/data/related_movies/"
export const UPDATE_VIEWS_URL = WEBSITE_URL + "/stats/updateViews/"
export const TRENDING_URL = WEBSITE_URL + "/data/trending/"
export const ACTORS_URL = WEBSITE_URL + "/data/actors/"
export const SEARCH_URL = WEBSITE_URL + "/data/search/"
export const LANG_LIST_URL = WEBSITE_URL + "/data/langList/"
export const STATS_URL = WEBSITE_URL + "/user/stats/"
export const REQUESTED_URL = WEBSITE_URL + "/data/requested/"

export const GET_STATISTICS_URL = WEBSITE_URL + "/get_statistics/"
export const PENDING_STREAMS_URL = WEBSITE_URL + "/pending_streams"
export const PENDING_STREAMS2_URL = WEBSITE_URL + "/pending_streams2"
export const PENDING_STREAMS3_URL = WEBSITE_URL + "/pending_streams3"

export const BEING_WATCHED_NOW_URL = WEBSITE_URL + "/beingWatchedNow"

