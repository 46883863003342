import { compose, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

export default initialState => {
    initialState = JSON.parse(window.localStorage.getItem("state")) || initialState;
    const middleware = [thunk];

    const store = createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(...middleware)
        )
    );

    store.subscribe(() => {
        const state = store.getState();
        //save the config to localStorage
        for (const setting in state.settings.settings) {
            if (state.settings.settings.hasOwnProperty(setting)) {
                const element = state.settings.settings[setting];
                localStorage.setItem(element.key, element.value)
            }
        }

        const persist = {
            favorites: state.favorites,
            // pages: state.pages,
            user: state.user,
        }

        localStorage.setItem('state', JSON.stringify(persist))
    })

    return store;
}