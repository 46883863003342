import { LOGIN, REGISTER, LOGOUT, UPDATE_INFO } from './actionTypes'

const initialState = {
    user: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            return { ...state, user: action.payload }
        case REGISTER:
            return { ...state, user: action.payload }
        case LOGOUT:
            return { ...state, user: action.payload }
        case UPDATE_INFO:
            return { ...state, user: action.payload }
        default:
            return state;
    }
}