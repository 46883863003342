import { BrowserRouter, Route, Switch } from 'react-router-dom'

// import App from './components/App';
import Loadable from 'react-loadable'
import React, { Component, Suspense } from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
import './index.css'
import './i18n';
import config from "./configs/main.json"
import "bootstrap/dist/css/bootstrap.min.css";

const Home = Loadable({
  loader: () => import('./components/Home'),
  loading: () => 'Loading...'
})

const Domains = Loadable({
  loader: () => import('./components/Home/domains'),
  loading: () => 'Loading...'
})

const Statistics = Loadable({
  loader: () => import('./components/Home/statistics'),
  loading: () => 'Loading...'
})

const Watch = Loadable({
  loader: () => import('./components/Watch'),
  loading: () => 'Loading...'
})

const Browse = Loadable({
  loader: () => import('./components/Browse'),
  loading: () => 'Loading...'
})

const Actors = Loadable({
  loader: () => import('./components/Browse/actors'),
  loading: () => 'Loading...'
})

const Login = Loadable({
  loader: () => import('./components/Auth/login'),
  loading: () => 'Loading...'
})

const Signup = Loadable({
  loader: () => import('./components/Auth/signup'),
  loading: () => 'Loading...'
})

const Account = Loadable({
  loader: () => import('./components/Account/'),
  loading: () => 'Loading...'
})
// const Admin = Loadable({
//   loader: () => import('./components/Admin/'),
//   loading: () => 'Loading...'
// })
const Watchlist = Loadable({
  loader: () => import('./components/Account/watchlist'),
  loading: () => 'Loading...'
})
const Links = Loadable({
  loader: () => import('./components/Account/links'),
  loading: () => 'Loading...'
})

const History = Loadable({
  loader: () => import('./components/Account/history'),
  loading: () => 'Loading...'
})
const Settings = Loadable({
  loader: () => import('./components/Account/settings'),
  loading: () => 'Loading...'
})

const FiretvApp = Loadable({
  loader: () => import('./components/Download/firetv'),
  loading: () => 'Loading...'
})
const AndroidApp = Loadable({
  loader: () => import('./components/Download/android'),
  loading: () => 'Loading...'
})

const ChangeDNS = Loadable({
  loader: () => import('./components/Footer/changeDNS/'),
  loading: () => 'Loading...'
})

const NotFound = Loadable({
  loader: () => import('./components/NotFound'),
  loading: () => 'Loading...'
})

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
}

class AppRender extends Component {


  render() {

    if (window.location.search == "?c=movie&m=firetv") {
      window.location.href = "https://firetv." + config[window.location.hostname == 'localhost' ? window.location.hostname : document.location.host.split(".").slice(-2).join(".")]["url"]
    }

    if (window.location.href.indexOf(".html") >= 0) {
      var url = window.location.href
      var id = url.split("-").pop().replace('.html', '')
      var title = url.split("/").pop().replace("-stream-" + id + ".html", '')
      var redirect = "/watch/" + title + "/" + id
      window.location = redirect
      return false;
    }

    return <Root>
      <BrowserRouter>
        <React.Fragment>
          <Route component={ScrollToTop} />
          <Switch>
            <Route path={'/'} exact component={Home} />
            <Route path={'/domains'} exact component={Domains} />
            <Route path={'/statistics'} exact component={Statistics} />
            <Route path={'/watch/:title/:id/:e'} exact component={Watch} />
            <Route path={'/watch/:title/:id'} exact component={Watch} />
            <Route path={'/browse/'} exact component={Browse} />
            <Route path={'/actors/'} exact component={Actors} />
            <Route path={'/login/'} exact component={Login} />
            <Route path={'/signup/'} exact component={Signup} />
            <Route path={'/account/'} exact component={Account} />
            {/* <Route path={'/admin/'} exact component={Admin} /> */}
            <Route path={'/account/watchlist'} exact component={Watchlist} />
            <Route path={'/account/links'} exact component={Links} />
            <Route path={'/account/history'} exact component={History} />
            <Route path={'/account/settings'} exact component={Settings} />
            <Route path={'/firetv_app/'} exact component={FiretvApp} />
            <Route path={'/android_app/'} exact component={AndroidApp} />
            <Route path={'/change_dns/'} exact component={ChangeDNS} />

            <Route component={NotFound} />
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    </Root>
  }
}

// ReactDOM.render(
//   <AppRender />,
//   document.getElementById('root')
// )



ReactDOM.render(
  <Suspense fallback={<div style={{ fontSize: "30px", textAlign: "center" }}>Loading...</div>}>
    <AppRender />
  </Suspense>,
  document.getElementById('root')
);